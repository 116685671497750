<template>
    <div class="card">
        <div style="background: #DBE7F2" class="p-2">


            <div class="row">
                <div class="col-sm-6 col-md-3 col-3">
                    <p class="h1 m-0">Deal List</p>
                </div>

                <div class="col-sm-6 col-md-6 col-6" style="position:relative" ref="dropdownRef">
                    <div class="input-group input-group-merge ms-1 w-100">
                        <span class="input-group-text">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search text-muted">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                        </span>
                      <input type="text" class="form-control"
                        id="chat-search" v-model.trim="searchValue"
                        @input="filterDeals"
                        placeholder="Quick Search.." aria-label="Search..."
                        aria-describedby="chat-search"/>

                        <span class="input-group-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search text-muted">
                                    <path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"></path>
                            </svg>
                        </span>
                    </div>

                    <div class="card _dropdown_search">
                        <div @click="onHandleSelect(item)" v-for="(item, index) in filteredDeals" :key="index" class="_list_item">
                            {{ item.title }}
                        </div>
                    </div>
                    
                </div>

                <div class="col-sm-6 col-md-3 col-3">
                    <div class="float-end">
                       
                        <button
                            style="min-width: 64px;margin-right: 25px;"
                            @click="onClickAddNewButton"
                            class="btn btn-primary waves-effect waves-float waves-light"
                        >+ Create new Deal
                        </button>

                    </div>
                </div>
            </div>

             <!-- <TitleButton btnTitle="Add New" :showBtn="false" :showAddNew="true" title="Deal List"
                @onClickAddNewButton="onClickAddNewButton">
                <template #title-details>
                    <div class="input-group input-group-merge ms-1 w-100">
                  <span class="input-group-text round"><i data-feather="search" class="text-muted"></i></span>
                  <input type="text" class="form-control round"
                    id="chat-search"
                    placeholder="Quick Search.." aria-label="Search..."
                    aria-describedby="chat-search"/>
                </div>
                </template>
            </TitleButton> -->

            
        </div>

        <div class="card p-2" style="min-height: 200px">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>

                            <th class="_head_pt">
                                <div class="form-check">
                                    <input class="form-check-input" @click="onHandleChecked" type="checkbox" v-model="isCheckedAll">
                                </div>
                            </th>

                            <th class="_head_pt">Photo</th>
                            <th class="_head_pt">Title</th>
                            <th>Campaign Name</th>
                            <th>Deal Owner</th>
                            <th>Deal Assingn To</th>
                            <th>Next Activity</th>
                            <th>Schedule Date</th>
                            <th class="_head_pt">Status</th>
                            <th class="_head_pt">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(deal, index) in deals" :key="index">
                            <td>
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" v-model="deal.isChecked">
                                </div>
                            </td>
                            <td>
                                <img :src="deal.img" class="me-75 rounded" height="50" width="50" alt="img" />
                            </td>

                            <td class="_wide_title_cell">
                                <span class="_deal_title">{{ deal.title }}</span>
                                <p>
                                    <span>{{ deal.contact_name }}</span> |
                                    <span>{{ deal.phone }}</span> 
                                </p>
                            </td>
                            <td>{{ deal.campaign_name }}</td>
                            <td>{{ deal.deal_owner}}</td>
                            <td>{{ deal.deal_assing_to }}</td>
                            <td>{{ deal.next_activity }}</td>
                            <td>{{ deal.schedule_date }}</td>
                            <!-- <td>{{ deal.status }}</td> -->
                            <td>
                                <span v-if="deal.status === 'Active'" class="badge rounded-pill badge-light-success me-1">{{ deal.status }}</span>
                                <span v-else class="badge rounded-pill badge-light-danger me-1">{{ deal.status }}</span>
                            </td>
                            <td>
                                <div class="dropdown">
                                    <button type="button" class="btn btn-sm dropdown-toggle hide-arrow py-0" data-bs-toggle="dropdown">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical" data-v-76160909="">
                                            <circle cx="12" cy="12" r="1"></circle>
                                            <circle cx="12" cy="5" r="1"></circle>
                                            <circle cx="12" cy="19" r="1"></circle>
                                        </svg>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-end">
                                        <a class="dropdown-item" href="#">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2 me-50" data-v-76160909="">
                                                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                            </svg>
                                            <span>Edit</span>
                                        </a>
                                        <a class="dropdown-item" href="#">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye me-50" data-v-76160909="">
                                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                <circle cx="12" cy="12" r="3"></circle>
                                            </svg>
                                            <span>View</span>
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>

        <div class="mb-2"></div>

         <div class="px-2 position-absolute bottom-0">
            <Pagination :offset="10"  ref="pagination" />
        </div>
        

    </div>
</template>

<script setup>

import Pagination from '@/components/atom/Pagination';
import {reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useDetectOutsideClick from '@/services/useDetectOutsideClick';
const router = useRouter()
const route = useRoute()
const searchValue = ref('');
const filteredDeals = reactive([]);
const deals = reactive([]);
const isCheckedAll = ref(false)


const dropdownRef = ref()

useDetectOutsideClick(dropdownRef, () => {
    filteredDeals.length = 0;
})
const dealTitles = reactive([
    'Distribution Agreements',
    'Software Sale',
    'Product Sale',
    'Accounting Service',
    'Distribution Agreements',
    'Software Sale',
    'Product Sale',
    'Accounting Service',
    'Product Sale',
    'Accounting Service',
])
function generateDummyData() {
    deals.length = 0;
    for (let i = 0; i < 10; i++){
        deals.push({
            id: i+1,
            img: 'http://dev.miaccounts.net/storage/ContactProfilePhoto/3524754ac7c75f38d1d1fe51facd243b5514.jpeg',
            title: dealTitles[i],
            contact_name: 'Jhon doe',
            phone: '+8801900000000',
            email: 'jhonedoe@gmail.com',
            location: 'Dhaka',
            campaign_name: 'Facebook',
            deal_owner: 'A.K. Traders',
            deal_assing_to: 'B.K. Traders',
            next_activity: 'Deals',
            schedule_date: '10/08/2023',
            status: 'Active',
            isChecked: false,
        })
    }
}
generateDummyData();

function onHandleSelect(deal) {
    const data = deals.filter(item => item.id === deal.id);
    deals.length = 0;
    deals.push(...data)
    filteredDeals.length = 0;
}
function filterDeals() {
    filteredDeals.length = 0;
    if (!searchValue.value) {
        generateDummyData();
        return;
    };
    const data =  deals.filter(function (item) {
        return item.title.toLowerCase().includes(searchValue.value.toLowerCase());
    });
    filteredDeals.push(...data)
}

function onHandleChecked(e) {
    const checkedValue = e.target.checked;
    if (checkedValue) {
        checkedAll();
        return;
    }
    unCheckedAll();
}

function checkedAll() {
    for (let i = 0; i < deals.length; i++){
        deals[i].isChecked = true;
    }
}

function unCheckedAll() {
    for (let i = 0; i < deals.length; i++) {
        deals[i].isChecked = false;
    }
}

function onClickAddNewButton() {
    router.push({ name: 'add-new-deal', params: route.params, query: route.query })
}


</script>


<style scoped>
._list_item:last-child {
    border: none;
}
._list_item {
    padding: 5px;
    margin: 5px;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
}
._list_item:hover {
    background: #f1ecec;
}
._dropdown_search {
    position: absolute;
    width: 95%;
    left: 5%;
    z-index: 99999;
}
._head_pt{
    padding-top: 2% !important;
}
._deal_title {
    font-size: 16px;
}
.table > :not(caption) > * > * {
    padding: 0.75rem 0.35rem;
}
._wide_img_cell{
    padding-left: 20px;
    padding-right: 20px;
}

</style>
